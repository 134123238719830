import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

import NavigationBar from './NavigationBar';

const About = () => {
  const { t } = useTranslation();

  const dataToNavigationbar = {
    aboutComponent: '',
  }

  return (
    <div>
      <NavigationBar navigationBarData={dataToNavigationbar} />
      <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
        <div className='main-content'>
        <Typography style={{ margin: "0 0 .75rem" }}>
            {t('about_page_info')}
          </Typography>
          <Typography style={{ margin: "0 0 .75rem" }}>
            {t('about_page_info_2')}
          </Typography>
          <Typography style={{ margin: "0 0 .75rem" }}>
            {t('logo_credit')} <a href="https://www.flaticon.com/" title="Site logo">Flaticon</a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default About;
